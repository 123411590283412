<template>
  <div class="container">
    <navigation msg="筛选"></navigation>
    <div class="searchBox"> 
      <!-- 学历 性别 -->
      <div class="searchTitle">学历</div>
      <div class="searchList">
        <div @click="handleEudc(item)" :class="item.isShow ? 'searchItem' : 'active'" v-for="(item,index) in educList" :key="index">{{item.title}}</div>
      </div>
      <div class="searchTitle">性别</div>
      <div class="searchList">
        <div @click="handleSex(item)" :class="item.isShow ? 'searchItem' : 'active'" v-for="(item,index) in sexList" :key="index">{{item.title}}</div>
      </div>
    </div>
    <div class="bottomBtn">
      <div class="bottomBtn1" @click="clear">清空</div>
      <div class="bottomBtn2" @click="submit">确认</div>
    </div>
   
  </div>
</template>
<script>
import { mapState,mapActions} from "vuex"
export default {
  data(){
    return {
      educList:[{
        title:"高中",
        val: 1,
        isShow:false,
      },{
        title:"大专",
        val: 2,
        isShow:false,
      },{
        title:"本科",
        val: 3,
        isShow:false,
      },{
        title:"硕士",
        val: 4,
        isShow:false,
      },{
        title:"博士",
        val: 5,
        isShow:false,
      },{
        title:"EMBA",
        val: 6,
        isShow:false,
      },{
        title:"MBA",
        val: 7,
        isShow:false,
      }],
      sexList:[{
        title:"男",
        val: 1,
        isShow:false,
      },{
        title:"女",
        val: 2,
        isShow:false,
      }],
      educVal:"",
      sexVal:""
    }
  },
   computed: {
    ...mapState({
      educList1: state => state.educList,
      sexList1: state => state.sexList,
    })
  },
  mounted(){
    if(this.educList1.length > 0){
      this.educList = this.educList1
    }
    if(this.sexList1.length > 0){
      this.sexList = this.sexList1
    }
  },
  methods:{
    handleEudc(e){
      let title = e.title
      let list = this.educList.map((item)=>{
        if(item.title == title){
          item.isShow = true
        } else {
          item.isShow = false
        }
        return item
      })
      this.educList = list
      this.educVal = e.val
    },
    handleSex(e){
      let title = e.title
      let list = this.sexList.map((item)=>{
        if(item.title == title){
          item.isShow = true
        } else {
          item.isShow = false
        }
        return item
      })
      this.sexList = list
      this.sexVal = e.val
    },
    clear(){
      this.$store.commit("SET_EDUC_LIST",[])
      this.$store.commit("SET_SEX_LIST",[])
       this.$store.commit("SET_EDUC_VAL","")
      this.$store.commit("SET_SEX_VAL","")
      setTimeout(()=>{
        this.$router.go(-1)
      },500)
    },
    submit(){
      this.$store.commit("SET_EDUC_LIST",this.educList)
      this.$store.commit("SET_SEX_LIST",this.sexList)
      this.$store.commit("SET_EDUC_VAL",this.educVal)
      this.$store.commit("SET_SEX_VAL",this.sexVal)

      setTimeout(()=>{
        this.$router.go(-1)
      },500)
    },
  }
}
</script>
<style scoped>
.bottomBtn{
  position: fixed;
  bottom:0;
  height:1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top:0.05rem solid #ddd;
}
.bottomBtn1{
  width: 40%;
  text-align: center;
  background: #FFFFFF;
  border-radius: 0.1rem;
  border: 0.01rem solid #FF3B24;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #FF4811;
  padding:0.15rem 0;
}
.bottomBtn2{
  width: 40%;
  text-align: center;
  background: #FF3B24;
  border-radius: 0.1rem;
  border: 0.01rem solid #FF3B24;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #fff;
  padding:0.15rem 0;
}
 .container{
  background-color: #fff;
  width: 100%;
  height:100vh
 }
.searchBox{
  padding:0.25rem;
  border-top:0.02rem solid #EEEEEE;
}
.searchTitle{
  font-size:0.4rem;
}
.searchList{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top:0.3rem;
}
.searchItem{
  margin-bottom:0.3rem;
  margin-right:0.3rem;
  padding:0.1rem 0.5rem;
  font-size: 0.3rem;
  background: #F2F4F7;
  border-radius: 0.07rem;
  border: 0.01rem solid #C5CDD4;
}
.active{
  margin-bottom:0.3rem;
  margin-right:0.3rem;
  padding:0.1rem 0.5rem;
  font-size: 0.3rem;
  background: #FFFFFF;
  border-radius: 0.07rem;
  border: 0.01rem solid #C5CDD4;
}
</style>